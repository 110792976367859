<template>
  <div class="container">
    <div class="putitle">
      <span
        :class="
          datas.position == 1
            ? 'left'
            : datas.position == 3
            ? 'right'
            : ''
        "
        >{{ $i18n.locale == 'en' ? datas.titleen : datas.title }}</span
      >
    </div>
    <div id="showimg1" class="pucontainer">
      <div
        class="citem"
        v-for="(item, i) in datas.item"
        :key="i"
        @click="golink(item.url)"
      >
        <q-img :src="item.picture" :ratio="20 / 27" />
        <div class="ctxt" v-if="item.title && item.contents">
          <h5>{{ item.title }}</h5>
          <p>{{ item.contents }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { golink } from '../index'

export default {
  name: 'showImg1',
  props: {
    datas: {
      type: Object
    }
  },
  setup () {
    return {
      golink
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin: 50px 0;
  .pucontainer {
    display: flex;
    justify-content: center;
    max-width: 1500px;
    margin: 0 auto;
    .citem {
      width: 400px;
      position: relative;
      color: #fff;
      cursor: pointer;
      transition: box-shadow 0.4s;
      &:hover {
        box-shadow: 0 2px 20px #bababa;
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
      .ctxt {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 30px;
        font-size: 30px;
        text-align: left;
        text-shadow: 1px 1px 2px #000;
        background-image: linear-gradient(
          180deg,
          transparent,
          rgba(0, 0, 0, 0.6)
        );
        h5 {
          margin: 0;
        }
        p {
          font-size: 14px;
          margin-bottom: 0;
          color: #fff;
        }
      }
    }
  }
}
</style>
